<template>
  <div>
    <top-nav></top-nav>
    <div class="container fluid">
      <h1 class="main-container display-4 mb-4">Past Season Results</h1>
      <h4 class="text-warning">Results best viewed on desktop or larger screen.</h4>


      <h1 class="mt-3">2023 Results</h1>
      <hr>

      <!-- SUNDAYS 2023 -->
      <h2 class="mt-3"><strong>Sundays</strong></h2>
      <h5 class="">Anglers of the Year: <span class="champColors">Mark & Kevin</span></h5>
      <h5 class="">Classic Champions: <span class="champColors">Ralph & Rodney</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#sunday2023"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="sunday2023">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vS1Q35wX8SHU6d9dz43L8HNKiDs782E9OMi9GCR_oaEtmic5lXDAUfV9OYEZzbB4WH59MALXqvWrp8c/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- MONDAYS 2023 -->
      <h2 class="mt-3"><strong>Monday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Mark & Kevin</span></h5>
      <h5>Classic Champion: <span class="champColors">Bob & Dan</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#monday2023"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="monday2023">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTo05PMqdTRLux6-Xnf9tg4QalYpIBhDeokH3UAz4gH-1k7lSR9glEEOoEyiQKCS9oXKVZBXc5aPP90/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- FRIDAYS 2023 -->
      <h2 class="mt-3"><strong>Friday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Ralph & Mike</span></h5>
      <h5>Classic Champions: <span class="champColors">Buck & TJ</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#friday2023"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="friday2023">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTAd0zszuIsPIM36fgfkGOLageA0ZxyP3iW2Ij-316IsV0Q5s4uMhb1ss3kWh16ekBniPQKA9wcmNcG/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <h1 class="mt-3">2022 Results</h1>
      <hr>
      <!-- SUNDAYS 2022 -->
      <h2 class="mt-3"><strong>Sundays</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Mark & Kevin</span></h5>
      <h5>Classic Champions: <span class="champColors">Bill & Will</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#sunday2022"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="sunday2022">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRK256yn2dxYouh00X4E0g7nDyfyJPTwlKZptM9JoKNGf2f3z2CENrOM_5Evoqu3srojXn8kNPSKQns/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- MONDAYS 2022 -->
      <h2 class="mt-3"><strong>Monday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Bob & Chad</span></h5>
      <h5>Classic Champion: <span class="champColors">Joey & Robbie</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#monday2022"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="monday2022">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vTPY4J6-0XIcdQozQ1RXSOE0CMWjjhkb4OprQv1frvIwW_nZn33Zq-88Q_UNTnioy6OrSNpkeqosElD/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- FRIDAYS 2022 -->
      <h2 class="mt-3"><strong>Friday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Jon & Aaron</span></h5>
      <h5>Classic Champions: <span class="champColors">Rodney & Mike</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#friday2022"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="friday2022">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vSWyiwrHE4hjS0pzSDaRvVypJIvcc6PuS8IPUEQQjkIgZwktl-OqellacCu4OPerWjjGShD3YfQ1d8r/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <h1 class="mt-3">2021 Results</h1>
      <hr>
      <!-- SUNDAYS 2021 -->
      <h2 class="mt-3"><strong>Sundays</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Mark & Kevin</span></h5>
      <h5>Classic Champions: <span class="champColors">Jon & Phil</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#sunday2021"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="sunday2021">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRBjC-5-l91rdHjrv0pSXSe6UOjmlX_yFpIUFzoar89r_GQrg9mDrFs5UEBqo9rmOIKnbWnhEbU2Mho/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- MONDAYS 2021 -->
      <h2 class="mt-3"><strong>Monday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Mark & Kevin</span></h5>
      <h5>Classic Champion: <span class="champColors">Jon</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#monday2021"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="monday2021">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRdWmJRk4w2P8ugTJU6IDWrhsVpD6NxGNL017doiLL9QRtc6TsZRZ1oIj5q7puP-bmel9HkoBros6tP/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>

      <!-- FRIDAYS 2021 -->
      <h2 class="mt-3"><strong>Friday Nights</strong></h2>
      <h5>Anglers of the Year: <span class="champColors">Joe & Adam</span></h5>
      <h5>Classic Champions: <span class="champColors">Ralph & Mike</span></h5>
      <button class="btn btn-primary btn mt-1 mb-3" type="button" data-bs-toggle="collapse" data-bs-target="#friday2021"
        aria-expanded="false" aria-controls="collapseExample">
        View Results
      </button>
      <div class="collapse" id="friday2021">
        <div class="card border-primary mb-3">
          <iframe class="resultsIframe"
            src="https://docs.google.com/spreadsheets/d/e/2PACX-1vRIujEkw1f1sAozZ8jHma4PRkxI6lSXdffBi60Ix0z_084E90gONHCfTOidAmiB62W6jigk7DLUC6h_/pubhtml?widget=true&amp;headers=false">
          </iframe>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav
  }
};
</script>