<template>
  <div>
    <top-nav></top-nav>

    <img src='../images/bass1.jpg' class="img-fluid">

    <h1 class="mt-3">Bass Fishing Tournaments in Southeastern Michigan</h1>

    <div class="container">

      <p class="display-text">
        My name is Ralph and I'm the tournament director of the Breaking Water
        Bass Club. Our club has been operating for over 33 years and we fish every Sunday morning and Friday evening. 
      </p>
      <p class="display-text">
        We'd love to have you come fish with us. If you have any questions
        please send me an email.
      </p>
      <address class="mt-3">
        Email <a href="mailto:BreakingWater01@aol.com">Ralph the Tournament Director</a>.<br>
      </address>
    </div>

    <div class="container mt-5">


      <h4 class="text-warning mt-3 text-left"><strong>2024 FRIDAY CLASSIC - Whitmore Lake is Off Limits beginning Saturday Aug 31st.</strong></h4>
    </br>     
      <h4 class="text-warning mt-3 text-left"><strong>2024 SUNDAY CLASSIC - Devils Lake is Off Limits beginning Monday Sept 2nd.</strong></h4>
    </br> 
      <!-- UPDATE WARNING FOR 2024 -->
      <div class="card mb-4">
        <div class="card-header py-3 bg-danger border-danger">
          <h4 class="my-0"><strong>MONDAY NIGHT INFO</strong></h4>
        </div>
        <div class="card-body">
          <h5>
            Monday night tournaments will be hosted by Joey and Robbie Colville on Ford Lake from 5:30-8:30pm. Entry fee is $50 (includes big bass) plus an optional $5 for second big bass.
          </h5>
        </div>
      </div>

      <div class="row mt-3">
        <h2 class="display-7 mb-4">Our Tournaments...</h2>
      </div>

      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-success border-success">
              <h4 class="my-0"><strong>Sunday Tournaments</strong></h4>
            </div>
            <div class="card-body">
              <ul class="list-unstyled mt-3 mb-4">
                <li class="h5 text-center">Sunday mornings</li>
                <li class="h5 text-center">$25 membership</li>
                <li class="h5 text-center">Free for women and kids under 18</li>
                <h1 class="card-title pricing-card-title">
                  $90<small class="text-muted fw-light"> entry</small>
                </h1>
              </ul>
              <RouterLink class="w-100 btn btn-lg btn-info" to="/sunday"><strong>Sunday Information</strong></RouterLink>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-success border-success">
              <h4 class="my-0"><strong>Friday Tournaments</strong></h4>
            </div>
            <div class="card-body">
              <ul class="list-unstyled mt-3 mb-4">
                <li class="h5 text-center">Friday nights 8pm-1am</li>
                <li class="h5 text-center">Fishing in the dark!</li>
                <li class="h5 text-center">No membership</li>
                <h1 class="card-title pricing-card-title">
                  $40<small class="text-muted fw-light"> entry</small>
                </h1>
              </ul>
              <RouterLink class="w-100 btn btn-lg btn-info" to="/friday"><strong>Friday Information</strong></RouterLink>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <h2 class="display-7 mb-4">2024 Top AOY Teams</h2>
      </div>
      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-primary border-primary">
              <h4 class="my-0"><strong>Sunday AOY Top 5</strong></h4>
            </div>
            <div class="card-body">
              <table id="sundayTopFiveTable" class="table table-dark">
                <thead>
                  <tr>
                    <th class="h4 table-header" scope="col">Team</th>
                    <th class="h4 table-header" scope="col">Points</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-primary border-primary">
              <h4 class="my-0"><strong>Friday AOY Top 5</strong></h4>
            </div>
            <div class="card-body">
              <table id="fridayTopFiveTable" class="table table-dark">
                <thead>
                  <tr>
                    <th class="h4 table-header" scope="col">Team</th>
                    <th class="h4 table-header" scope="col">Points</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <h2 class="display-7 mb-4">2024 Big Bass of the Year</h2>
      </div>
      <div class="row row-cols-1 row-cols-md-2 mb-3 text-center">
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-primary border-primary">
              <h4 class="my-0"><strong>Sunday Big Bass YTD</strong></h4>
            </div>
            <div class="card-body">
              <table id="sundayBigBassTable" class="table table-dark">
                <thead>
                  <tr>
                    <th class="h4 table-header" scope="col">Team</th>
                    <th class="h4 table-header" scope="col">Weight</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card mb-4">
            <div class="card-header py-3 text-white bg-primary border-primary">
              <h4 class="my-0"><strong>Friday Big Bass YTD</strong></h4>
            </div>
            <div class="card-body">
              <table id="fridayBigBassTable" class="table table-dark">
                <thead>
                  <tr>
                    <th class="h4 table-header" scope="col">Team</th>
                    <th class="h4 table-header" scope="col">Weight</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sponsor-carousel></sponsor-carousel>
  </div>
</template>
<script>
import TopNav from '../components/TopNav.vue';
import SponsorCarousel from '../components/SponsorCarousel.vue';
import { sundayTopFive, sundayBigBass, fridayTopFive, fridayBigBass } from '../data/results.js';
import { buildTable } from '../js/utilities.js';
export default {
  components: {
    TopNav,
    SponsorCarousel
  },
  mounted() {
    buildTable("sundayTopFiveTable", sundayTopFive);
    buildTable("sundayBigBassTable", sundayBigBass);
    buildTable("fridayTopFiveTable", fridayTopFive);
    buildTable("fridayBigBassTable", fridayBigBass);
  }
};
</script>
