import Vue from 'vue';
import App from './App.vue';
import router from './router';
// import firebase from '@/firebase';

Vue.config.productionTip = false;

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bootstrap-superhero.min.css';
import './css/custom.css';

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
