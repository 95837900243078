var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('top-nav'),_c('img',{staticClass:"img-fluid",attrs:{"src":require("../images/bass1.jpg")}}),_c('h1',{staticClass:"mt-3"},[_vm._v("Bass Fishing Tournaments in Southeastern Michigan")]),_vm._m(0),_c('div',{staticClass:"container mt-5"},[_vm._m(1),_c('br'),_vm._m(2),_c('br'),_vm._m(3),_vm._m(4),_c('div',{staticClass:"row row-cols-1 row-cols-md-2 mb-3 text-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_vm._m(5),_c('div',{staticClass:"card-body"},[_vm._m(6),_c('RouterLink',{staticClass:"w-100 btn btn-lg btn-info",attrs:{"to":"/sunday"}},[_c('strong',[_vm._v("Sunday Information")])])],1)])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_vm._m(7),_c('div',{staticClass:"card-body"},[_vm._m(8),_c('RouterLink',{staticClass:"w-100 btn btn-lg btn-info",attrs:{"to":"/friday"}},[_c('strong',[_vm._v("Friday Information")])])],1)])])]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]),_c('sponsor-carousel')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('p',{staticClass:"display-text"},[_vm._v(" My name is Ralph and I'm the tournament director of the Breaking Water Bass Club. Our club has been operating for over 33 years and we fish every Sunday morning and Friday evening. ")]),_c('p',{staticClass:"display-text"},[_vm._v(" We'd love to have you come fish with us. If you have any questions please send me an email. ")]),_c('address',{staticClass:"mt-3"},[_vm._v(" Email "),_c('a',{attrs:{"href":"mailto:BreakingWater01@aol.com"}},[_vm._v("Ralph the Tournament Director")]),_vm._v("."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-warning mt-3 text-left"},[_c('strong',[_vm._v("2024 FRIDAY CLASSIC - Whitmore Lake is Off Limits beginning Saturday Aug 31st.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h4',{staticClass:"text-warning mt-3 text-left"},[_c('strong',[_vm._v("2024 SUNDAY CLASSIC - Devils Lake is Off Limits beginning Monday Sept 2nd.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header py-3 bg-danger border-danger"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("MONDAY NIGHT INFO")])])]),_c('div',{staticClass:"card-body"},[_c('h5',[_vm._v(" Monday night tournaments will be hosted by Joey and Robbie Colville on Ford Lake from 5:30-8:30pm. Entry fee is $50 (includes big bass) plus an optional $5 for second big bass. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3"},[_c('h2',{staticClass:"display-7 mb-4"},[_vm._v("Our Tournaments...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 text-white bg-success border-success"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Sunday Tournaments")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled mt-3 mb-4"},[_c('li',{staticClass:"h5 text-center"},[_vm._v("Sunday mornings")]),_c('li',{staticClass:"h5 text-center"},[_vm._v("$25 membership")]),_c('li',{staticClass:"h5 text-center"},[_vm._v("Free for women and kids under 18")]),_c('h1',{staticClass:"card-title pricing-card-title"},[_vm._v(" $90"),_c('small',{staticClass:"text-muted fw-light"},[_vm._v(" entry")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header py-3 text-white bg-success border-success"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Friday Tournaments")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"list-unstyled mt-3 mb-4"},[_c('li',{staticClass:"h5 text-center"},[_vm._v("Friday nights 8pm-1am")]),_c('li',{staticClass:"h5 text-center"},[_vm._v("Fishing in the dark!")]),_c('li',{staticClass:"h5 text-center"},[_vm._v("No membership")]),_c('h1',{staticClass:"card-title pricing-card-title"},[_vm._v(" $40"),_c('small',{staticClass:"text-muted fw-light"},[_vm._v(" entry")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3"},[_c('h2',{staticClass:"display-7 mb-4"},[_vm._v("2024 Top AOY Teams")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-cols-1 row-cols-md-2 mb-3 text-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header py-3 text-white bg-primary border-primary"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Sunday AOY Top 5")])])]),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-dark",attrs:{"id":"sundayTopFiveTable"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Team")]),_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Points")])])])])])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header py-3 text-white bg-primary border-primary"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Friday AOY Top 5")])])]),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-dark",attrs:{"id":"fridayTopFiveTable"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Team")]),_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Points")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-3"},[_c('h2',{staticClass:"display-7 mb-4"},[_vm._v("2024 Big Bass of the Year")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row row-cols-1 row-cols-md-2 mb-3 text-center"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header py-3 text-white bg-primary border-primary"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Sunday Big Bass YTD")])])]),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-dark",attrs:{"id":"sundayBigBassTable"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Team")]),_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Weight")])])])])])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card mb-4"},[_c('div',{staticClass:"card-header py-3 text-white bg-primary border-primary"},[_c('h4',{staticClass:"my-0"},[_c('strong',[_vm._v("Friday Big Bass YTD")])])]),_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-dark",attrs:{"id":"fridayBigBassTable"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Team")]),_c('th',{staticClass:"h4 table-header",attrs:{"scope":"col"}},[_vm._v("Weight")])])])])])])])])
}]

export { render, staticRenderFns }