import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Sunday from '../views/Sunday.vue';
import Friday from '../views/Friday.vue';
import Past from '../views/Past.vue';
import Sponsors from '../views/Sponsors.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'Breaking Water Bass Club' }
  },
  {
    path: '/sunday',
    name: 'Sunday',
    component: Sunday,
    meta: { title: 'Breaking Water Bass Club' }
  },
  {
    path: '/friday',
    name: 'Friday',
    component: Friday,
    meta: { title: 'Breaking Water Bass Club' }
  },
  {
    path: '/past',
    name: 'Past',
    component: Past,
    meta: { title: 'Breaking Water Bass Club' }
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors,
    meta: { title: 'Breaking Water Bass Club' }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.afterEach((to) => {
  document.title = to.meta.title || 'Breaking Water Bass Club';
});

export default router;
