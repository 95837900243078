  
function buildTable(id, data) {
  // Get a reference to the table.
  var table = document.getElementById(id);

  // Loop through the data and create a new row for each item.
  for (var i = 0; i < data.length; i++) {
    var row = table.insertRow(-1);
    row.className = "table-text";

    // Create a new cell for each column in the data.
    for (var j = 0; j < data[i].length; j++) {
      var cell = row.insertCell(j);

      // Set the text content of the cell.
      cell.textContent = data[i][j];
    };
  }
}

export { buildTable };
