<template>
  <div>
    <top-nav></top-nav>
    <div class="container">
      <h1 class="main-container display-4">Sponsors</h1>
      <p class="display-text">
        Our sponsors are critical to the success of the Breaking Water Bass
        Club. Please make sure to visit them, use their services and thank them
        for their support!
      </p>

      <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3 mb-5">
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/MilanDrywall.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Milan Drywall Contracting Inc.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/APlusHVAC.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">A Plus Heating & Cooling</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/MartyBeckett.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Marty Beckett's Ceramic Tile</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/HHS.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Henry H. Smith Co.</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/FSM.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Foundation Systems of Michigan</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/BobbySmithCorvetteShop.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Bobby Smith's Corvette Shop</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/PizzaPerfect.jpeg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Pizza Perfect 485-8800</div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card shadow-sm">
            <img
              src="../sponsors/Milan Crossfit.jpg"
              class=""
              alt="..."
            />
            <div class="card-body">
              <div class="display-text">Milan Crossfit</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TopNav from '../components/TopNav.vue';
export default {
  components: {
    TopNav,
  },
};
</script>
